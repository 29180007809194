import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon Angular library</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Development</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row mdxType="Row">
      <Column offsetLg="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Try Angular components with CodeSandbox." href="https://codesandbox.io/s/0129r494ql" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "codesandbox",
              "title": "codesandbox",
              "src": "/static/982215411e3a94425e6a2d19aa0bbd79/7d71f/codesandbox.png",
              "srcSet": ["/static/982215411e3a94425e6a2d19aa0bbd79/7d71f/codesandbox.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "carbon-angular-library"
    }}>{`Carbon Angular library`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://angular.carbondesignsystem.com/"
      }}>{`library`}</a>{` provides front-end developers & engineers a collection of reusable Angular components to build websites and user interfaces. Adopting the library enables developers to use consistent markup, styles, and behavior in prototype and production work.`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`Assuming we’re starting with a new @angular/cli project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ npx @angular/cli new my-project --style=scss
$ cd my-project
$ npm i --save carbon-components-angular carbon-components
`}</code></pre>
    <p>{`Then we need to include carbon-components in `}<inlineCode parentName="p">{`src/styles.scss`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '~carbon-components/scss/globals/scss/styles.scss';
`}</code></pre>
    <p>{`That’s it! Now start the server and start building.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ npm start
`}</code></pre>
    <p><em parentName="p">{`Note: This isn’t the only way to bootstrap a`}</em>{` `}<inlineCode parentName="p">{`carbon-components-angular`}</inlineCode>{` `}<em parentName="p">{`application, but the combination of`}</em>{` `}<inlineCode parentName="p">{`@angular/cli`}</inlineCode>{` `}<em parentName="p">{`and the`}</em>{` `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{` `}<em parentName="p">{`scss is our recommended setup.`}</em></p>
    <h3 {...{
      "id": "using-our-starter-app"
    }}>{`Using our starter app`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.46428571428571%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "carbon-angular-starter screenshot",
        "title": "carbon-angular-starter screenshot",
        "src": "/static/049ae87bf94760b52bc939548014eead/fb070/carbon-angular-starter.png",
        "srcSet": ["/static/049ae87bf94760b52bc939548014eead/d6747/carbon-angular-starter.png 288w", "/static/049ae87bf94760b52bc939548014eead/09548/carbon-angular-starter.png 576w", "/static/049ae87bf94760b52bc939548014eead/fb070/carbon-angular-starter.png 1152w", "/static/049ae87bf94760b52bc939548014eead/fb104/carbon-angular-starter.png 1728w", "/static/049ae87bf94760b52bc939548014eead/8fefe/carbon-angular-starter.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`We recommend using the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-angular-starter"
      }}>{`carbon-angular-starter`}</a>{` for bootstrapping applications with Carbon components. Within five minutes your app will be running with the following already configured:`}</p>
    <ul>
      <li parentName="ul">{`Angular-cli`}</li>
      <li parentName="ul">{`Build process`}</li>
      <li parentName="ul">{`Code styles and editor configs`}</li>
      <li parentName="ul">{`Folder structure`}</li>
      <li parentName="ul">{`Lazy loading`}</li>
      <li parentName="ul">{`Routing`}</li>
      <li parentName="ul">{`Service workers`}</li>
      <li parentName="ul">{`Test framework`}</li>
    </ul>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-angular-starter"
      }}>{`readme`}</a>{` for installation instructions.`}</p>
    <h2 {...{
      "id": "development"
    }}>{`Development`}</h2>
    <p>{`Please refer to the `}<a parentName="p" {...{
        "href": "https://github.com/IBM/carbon-components-angular/blob/master/README.md#contributing"
      }}>{`contributing guidelines`}</a>{` before starting any work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      